let config = require('./config.global')

config.env = 'development'
config.hostname = 'localhost'
config.mongo.db = 'local_db'

// config.api.delivery = 'http://localhost:3000/'

config.geofence.savetime = 30 // 3 sec

module.exports = config;
