import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from './config'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        status: '',
        secu: '',
        token: '',
        timezone: '',
        user: {},
        settings: {},
        geofences: '',
        needSave: '',
        showMarkers: { // выполненные заказы, заказы, партнеры, курьеры, стрелки
            ordersComplete: false,
            orders: true,
            partners: true,
            couriers: true,
            arrows: true
        },
    },
    plugins: [createPersistedState()],
    mutations: {
        needSave: (state, data) => (state.needSave = data),
        geofences: (state, data) => (state.geofences = data),
        showMarkers: (state, data) => (state.showMarkers = data),
        auth_request(state) {
            console.log('state auth_request')
            state.status = 'loading'
        },
        secu_init(state, data) {
            // секу нужен для того чтобы все аккаунты без поля секу автоматически вышли и потребовали вход через новый пароль
            console.log('state secu_init')
            state.secu = 'secu'
        },
        auth_success(state, data) {
            console.log('state auth_success', data.token, data.user)
            state.status = 'success'

            state.token = data.token
            state.user = data.user
            state.settings = data.user.settings
            state.timezone = data.user.settings.timezone
            if (!state.timezone) state.timezone = config.timezone
            delete state.user.settings
        },
        auth_error(state) {
            console.log('state auth_error')
            state.status = 'error'
        },
        logout(state) {
            console.log('state logout')
            state.status = ''
            state.token = ''
            state.timezone = ''
        },
        settings(state, data) {
            console.log('state settings', data)
            state.settings = data
        },
    },
    actions: {
        address_to_coord({ commit }, address) {
            return new Promise((resolve, reject) => {
                const params = {
                    address,
                }
                axios
                    .get('address_to_coord', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        geofences_get({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    tid: this.state.user.tid,
                }
                axios
                    .get('geofences', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        geofences_set({ commit }, geofences) {
            return new Promise((resolve, reject) => {
                const params = {
                    data: JSON.stringify(geofences),
                    tid: this.state.user.tid
                }
                axios
                    .post('geofences', params)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        settings_get({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    login: this.state.user.name,
                }
                axios
                    .get('user_settings', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        settings_set({ commit }, settings) {
            return new Promise((resolve, reject) => {
                axios
                    .post('user_settings',  {
                        login: this.state.user.name,
                        settings,
                    })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        partner_set({ commit }, partner) {
            return new Promise((resolve, reject) => {
                axios
                    .post('partner', partner)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        user_set({ commit }, user) {
            return new Promise((resolve, reject) => {
                axios
                    .post('user', user)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        users_get({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    dispatcher_tid: this.state.user.tid
                }
                axios
                    .get('dispatcher_users', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        users_set({ commit }, users) {
            return new Promise((resolve, reject) => {
                axios
                    .post('users', { users })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        order_delete({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete('order', { data: { id } })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        order_set({ commit }, order) {
            return new Promise((resolve, reject) => {
                axios
                    .post('order', order)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        courier_orders_reset({ commit }, courier_tid) {
            return new Promise((resolve, reject) => {
                axios
                    .post('courier_orders_reset', { courier_tid })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        orders_export({ commit }, data) {
            return new Promise((resolve, reject) => {
                let courier
                if (data.courier == null) {
                    courier = 'null'
                } else if (data.courier == 0) {
                    courier = 0
                } else {
                    courier = data.courier
                }
                const params = {
                    dispatcher_tid: this.state.user.tid,
                    date1: data.date1,
                    date2: data.date2,
                    partner: data.partner,
                    courier,
                    type: data.type,
                }
                axios
                    .get('orders_export', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('err', err)
                        reject(err)
                    })
            })
        },
        orders_date({ commit }, data) {
            return new Promise((resolve, reject) => {
                let courier
                if (data.courier == null) {
                    courier = 'null'
                } else if (data.courier == 0) {
                    courier = 0
                } else {
                    courier = data.courier
                }
                const params = {
                    dispatcher_tid: this.state.user.tid,
                    date1: data.date1,
                    date2: data.date2,
                    partner: data.partner,
                    courier,
                    mode: data.mode,
                    deliveryTime: data.deliveryTime,
                }
                axios
                    .get('orders_date', {params})
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('err', err)
                        reject(err)
                    })
            })
        },
        orders_today({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    dispatcher_tid: this.state.user.tid
                }
                axios
                    .get('orders_all_today', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        orders_yesterday({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    dispatcher_tid: this.state.user.tid
                }
                axios
                    .get('orders_all_yesterday', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        orders_month({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    dispatcher_tid: this.state.user.tid
                }
                axios
                    .get('orders_all_month', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        objects({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get('objects')
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        partner_users({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    dispatcher_tid: this.state.user.tid
                }
                axios
                    .get('dispatcher_partner_users', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        partners({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    dispatcher_tid: this.state.user.tid
                }
                axios
                    .get('dispatcher_partners', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        partners_get({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    dispatcher_tid: this.state.user.tid
                }
                axios
                    .get('partners',{ params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        partners_update_coordinates({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    dispatcher_tid: this.state.user.tid
                }
                axios
                    .post('partners_update_coordinates', params)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        partner_update_coordinates({ commit }, partner_id) {
            return new Promise((resolve, reject) => {
                const params = {
                    // dispatcher_tid: this.state.user.tid,
                    partner_id,
                }
                axios
                    .post('partner_update_coordinates', params)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        partner_delete({ commit }, id) {
            console.log('partner_delete id', id)
            return new Promise((resolve, reject) => {
                axios
                    .delete('partner', { data: { id } })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        partner_address({ commit }, data) {
            return new Promise((resolve, reject) => {
                const params = {
                    dispatcher_tid: this.state.user.tid,
                    partner_id: data.partner_id,
                    address: data.address,
                }
                axios
                    .get('partner_address',{ params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        address_to_geofence({ commit }, address) {
            return new Promise((resolve, reject) => {
                const params = {
                    dispatcher_tid: this.state.user.tid,
                    address,
                }
                axios
                    .get('address_to_geofence', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        couriers_get({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    dispatcher_tid: this.state.user.tid
                }
                axios
                    .get('couriers', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        auth({ commit }, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                commit('secu_init')

                axios
                    .post('login',  {
                        name: user.name,
                        pass: user.password,
                    })
                    .then(resp => {
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp.data.token
                        commit('auth_success', resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                commit('logout')
                this.state.geofences = {}
                delete axios.defaults.headers.common['Authorization']
                resolve()
            })
        },
    },
    getters: {
        isLoggedIn: state => {
            return !!state.token && !!state.secu
        },
    //     authStatus: state => state.status,
    //     authUser: state => state.user,
    //     settings: state => state.settings,
    //     token: state => state.token,
    },
})
