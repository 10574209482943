<template>
    <v-container class="login fill-height" fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
                <v-card class="elevation-20">
                    <v-toolbar color='#031E4D' dark flat>
                        <v-toolbar-title>Darall DELIVERY CONSOLE</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <!-- <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn :href="source" icon large target="_blank" v-on="on">
                                    <v-icon>mdi-code-tags</v-icon>
                                </v-btn>
                            </template>
                            <span>Source</span>
                        </v-tooltip> -->
                    </v-toolbar>
                    <v-card-text>
                        <v-form>
                            <v-text-field label="Login" id="name" name="name" prepend-icon="mdi-account" type="text" v-model="name" v-on:keyup.enter="auth"></v-text-field>
                            <v-text-field label="Password" id="password" name="password" prepend-icon="mdi-lock" type="password" v-model="password" v-on:keyup.enter="auth"></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color='#031E4D' class="px-8" text-color="white" @click="auth" dark>Войти</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: {
            source: String,
        },
        data() {
            return {
                name: '',
                password: '',
            }
        },
        methods: {
            auth: function() {
                let name = this.name
                let password = this.password
                this.$store
                    .dispatch('auth', { name, password })
                    .then(() => this.$router.push('/'))
                    .catch(err => console.log(err))
            },
        },
    }
</script>

<style lang="stylus">
    html
        overflow-y auto !important

    #app
      width 100%
      height 100%
      z-index 1
      display block
      position absolute

    .login
        background url("../../assets/images/background.jpg") 100% 100% no-repeat
        background-size cover

        // background url("../../assets/images/bg-dental.png")
        // background: url("../assets/images/bg-dental.png") -40px -35px;
        // top 0
        // left 0
        // bottom 0
        // right 0
        // margin 0
        // padding 0
        // height 100%
        // min-width: 1024px !important;
</style>
