import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store.js'
import Login from '@/components/auth/Login.vue'
import AppLayout from '@/components/AppLayout'

const Analitics = () => import('@/components/analitics/Analitics')
const Geofence = () => import('@/components/geofence/Geofence')
const Delivery = () => import('@/components/delivery/Delivery')
// const Chat = () => import('@/components/chat/Chat')
const Partners = () => import('@/components/partners/Partners')
const Couriers = () => import('@/components/couriers/Couriers')
const Orders = () => import('@/components/orders/Orders')
const Guests = () => import('@/components/guests/Guests')
const Settings = () => import('@/components/settings/Settings')

Vue.use(Router)

let router = new Router({
    mode: 'history',
    hash: false,
    routes: [
        {
            name: 'app',
            path: '/',
            component: AppLayout,
            meta: {
                requiresAuth: true,
            },
            redirect: { name: 'analitics' },
            children: [
                {
                    name: 'analitics',
                    path: 'analitics',
                    component: Analitics,
                    default: true,
                },
                {
                    name: 'geofence',
                    path: 'geofence',
                    component: Geofence,
                    // default: true,
                },
                // {
                //     name: 'chat',
                //     path: 'chat',
                //     component: Chat,
                // },
                {
                    name: 'couriers',
                    path: 'couriers',
                    component: Couriers,
                },
                {
                    name: 'partners',
                    path: 'partners',
                    component: Partners,
                },
                {
                    name: 'orders',
                    path: 'orders',
                    component: Orders,
                },
                {
                    name: 'guests',
                    path: 'guests',
                    component: Guests,
                },
                {
                    name: 'delivery',
                    path: 'delivery',
                    component: Delivery,
                },
                {
                    name: 'settings',
                    path: 'settings',
                    component: Settings,
                },
            ],
        },
        {
            name: 'login',
            path: '/login',
            component: Login,
        },
        {
            path: '**',
            // name: 'http404',
            // component: http404,
            // meta: {
            //     requiresAuth: true,
            // },
            redirect: { name: 'analitics' },
        },
    ],
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

export default router
