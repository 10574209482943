let config = module.exports = {};

config.env = 'development'
config.hostname = 'darall.mynex.ru'

// config.dispatcher_tid = 1374806962 // Darall
// config.dispatcher_tid = 469966702 // Alexey Voloshin

// mongo database
config.mongo = {}
config.mongo.uri = process.env.MONGO_URI || 'localhost'
config.mongo.db = 'example_dev'

// map
config.map = {}
// config.map.url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
config.map.url = 'https://map.mynex.ru/tile/{z}/{x}/{y}.png'

// timezone
config.timezone = '+10:00',
config.timezoneString = 'Asia/Vladivostok',

// api
config.api = {}
// config.api.url = 'https://api.darall.mynex.ru/'
config.api.delivery = 'https://api.darall.mynex.ru/'
config.api.mynex = 'https://api.mynex.ru/v1/'

// geofence
config.geofence = {}
config.geofence.savetime = 40 // время перед сохранением 4 секунды
config.geofence.name = '' // название геозоны
config.geofence.price = [195, 0, 0, 0] // цена поездки
config.geofence.notify = false // уведомлять или нет при посещении
config.geofence.color = {}
config.geofence.color.count = 15 // количество цветов
config.geofence.color.saturation = 80 // контрастность
config.geofence.color.lightness = 50 // яркость
config.geofence.color.random = true // случайный цвет при создании
config.geofence.color.index = 0 // индекс цвета при random=false

// user
config.user = {}
// user status
config.user.status = {}
config.user.status.free = 0
config.user.status.processing = 1
config.user.status.delivering = 2
// user roles
config.user.role = {}
config.user.role.none = 0
config.user.role.dispatcher = 1
config.user.role.courier = 2
config.user.role.partner = 3

// order
config.order = {}
// order status
config.order.status = {}
config.order.status.new = 0
config.order.status.way_partner = 1
config.order.status.way_guest = 2
config.order.status.complete = 3
config.order.status.forming = 4
config.order.status.passed = 5
config.order.status.deleted = 6