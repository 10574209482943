import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import axios from 'axios'
import Vuex from 'vuex'
import config from './config'

import VueDragAndDropList from 'vue-drag-and-drop-list'
Vue.use(VueDragAndDropList)

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import { languages } from './i18n'
import { defaultLocale } from './i18n'
const messages = Object.assign(languages)

Vue.config.productionTip = false

Vue.prototype.$store = store

// store.commit('logout')

localStorage.removeItem('user')
localStorage.removeItem('token')
localStorage.removeItem('settings')

Vue.prototype.$http = axios
const token = store.state.token
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
}
if (!store.state.timezone) {
    store.state.timezone = config.timezone
}
console.log('tz from main', store.state.timezone)
Vue.prototype.$http.defaults.headers.common['TZ'] = store.state.timezone
Vue.prototype.$http.defaults.baseURL = config.api.delivery

console.log('token from main', token)

import notify from 'vuetify-notify'
Vue.use(notify, {
    vuetify,
    options: {
        toast: {
            x: 'center',
            y: 'bottom',
            color: 'primary',
        },
        dialog: {
            width: 400,
        },
    },
})

var i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: 'ru',
    messages,
})

Vue.use(Vuex)

Vue.prototype.$vm = new Vue({
    vuetify,
    router,
    i18n,
    render: h => h(App),
}).$mount('#app')
