<template>
    <div style="height:100%">
        <v-navigation-drawer app v-model="drawer" :width="240" :fixed="true" :color="'#031E4D'" dark>
        <!-- <v-navigation-drawer app v-model="drawer" :width="240" :fixed="true" :color="'#031E4D'" :expand-on-hover="false" :mini-variant="false" :right="false" :permanent="true" dark> -->
            <v-list dense nav class="py-0">
                <v-list-item two-line :class="miniVariant && 'px-0'">
                    <v-list-item-avatar>
                        <img src="../assets/images/darall.jpg" />
                    </v-list-item-avatar>

                    <v-list-item-content>
                        Darall
                        <v-list-item-subtitle>DELIVERY CONSOLE</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item v-for="(item, index) in menuItems" :key="index" link :to="{ name: item.name }" @change="menuItemChange(index)">
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        {{ $t('menu.' + item.name) }}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar class="main-bar px-4" :elevation="1" :dark="false" :height="64" app>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title>{{ toolbarTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>

            <!-- {{ toolbarContent }} -->

            <v-spacer></v-spacer>

            <div class="text-center">
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(item, index) in profileItems" :key="index" @click="item.func">
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-app-bar>

        <v-main class="main-content" app>
            <router-view />
        </v-main>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                drawer: null,
                menuItems: [
                    { name: 'analitics', icon: 'mdi-chart-donut' },
                    { name: 'orders', icon: 'mdi-inbox-multiple' },
                    { name: 'partners', icon: 'mdi-home-group' },
                    { name: 'couriers', icon: 'mdi-car-multiple' },
                    { name: 'guests', icon: 'mdi-account-multiple' },
                    { name: 'delivery', icon: 'mdi-map-marker-radius-outline' },
                    { name: 'geofence', icon: 'mdi-vector-rectangle' },
                    { name: 'settings', icon: 'mdi-cog-outline' },
                ],
                color: 'primary',
                colors: ['primary', 'blue', 'success', 'red', 'teal'],
                right: false,
                permanent: true,
                miniVariant: false,
                expandOnHover: false,
                background: false,
                profileItems: [
                    { title: 'Профиль', func: this.onProfile, icon: 'mdi-account' },
                    { title: 'Выход', func: this.onExit, icon: 'mdi-exit-to-app' },
                ],
                toolbarTitle: '',
                toolbarContent: '---',
                main_content: 'Hello World!',
            }
        },
        mounted() {
            console.log('this.$router.currentRoute', this.$router.currentRoute)
            const index = this.menuItems.findIndex(item => item.name == this.$router.currentRoute.name)
            this.menuItemChange(index)
        },
        computed: {
            bg() {
                return this.background ? 'https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg' : undefined
            },
        },
        methods: {
            onProfile() {},
            onExit() {
                this.$store.dispatch('logout').then(() => this.$router.push('/login'))
            },
            menuItemChange(index) {
                this.toolbarTitle = this.$t(this.menuItems[index].name + '.title')
            },
        },
    }
</script>

<style lang="stylus" scoped>
    .v-sheet.v-card
      border-radius 0
    //   border-top-right-radius 0
    //   border-bottom-right-radius 0
    .main
        width 100%
        height 100%
    .v-card__text
        padding 0
    .main-bar
        // left 256px
    .main-content
        padding 0 !important
        height 100%
    .v-divider
        padding-bottom 8px
    .v-application
        height 100% !important
    .v-main
        height 100% !important
    .container
        height 100%

    .v-list-item--two-line .v-list-item__avatar:not(.v-list-item__avatar--horizontal)
        margin-top 0
        margin-bottom 0

    .v-application--is-ltr .v-list-item__icon:first-child
        margin-right 14px
        margin-left 6px


</style>
